:root {
    --main-background-color: var(--bs-body-bg);
    --hover-color: var(--hover-bg);
    --active-color: #E7DFD3;
    --text-color: var(--bs-body-color);

    /* Dashboard */
    --dashboard-icon-size: 2rem;
    --dashboard-icon-color: var(--bs-primary);
    --dashboard-card-gap: 1.5rem;
    --dashboard-stats-font-size: 2rem;
}

html, body {
    font-family: var(--bs-body-font-family);
    background-color: var(--bs-body-bg);
    min-height: 100vh;
    color: var(--bs-body-color);
}

html, body, #root, .App {
    height: 100vh;
}

.App {
    padding-top: 3rem;
}

.loading-indicator {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background-color: rgba(249, 246, 242, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-body-color);
    font-weight: 600;
    font-size: 1.5rem;
}

.loading-indicator button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--bs-body-color);
    border: 0;
    font-size: 1rem;
}

/* Welcome */
.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
}

.welcome-logo {
    margin-bottom: 2rem;
    text-align: center;
}

.welcome-logo img {
    height: 50px;
}

.auth-box {
    max-width: 400px;
    width: 100%;
}

/* Main container */
.main-container {
    background-color: var(--bs-body-bg);
    position: relative;
    display: flex;
    justify-content: stretch;
}

.page {
    margin: 0;
}

.page-narrow {
    max-width: 50rem;
}

.main-content {
    flex-grow: 1;
    padding: 2.5rem 1rem;
    overflow: auto;
}

/* Sidebar */
.sidebar-container {
    max-width: 22rem;
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: left 0.3s;
    z-index: 1000;
}

.sidebar-container.open {
    left: 0;
}

.sidebar-toggle-header {
    display: flex;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    align-items: center;
    background-color: var(--bs-body-bg);
}

.sidebar-toggle-header .btn {
    border: 0;
    margin-right: 1.5rem;
}

.sidebar-toggle-header-mobile {
    padding-top: 1rem;
    padding-left: 2.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.sidebar {
    overflow-y: auto;
    position: sticky;
    top: 0;
    padding-top: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-logo {
    display: flex;
}

.sidebar-logo img {
    height: 1.5rem;
}

.sidebar-menu {
    flex-grow: 1;
    margin-bottom: 3;
}

.sidebar-account {
    margin-bottom: 3rem;
}

.sidebar .nav-link {
    color: var(--bs-body-color);
    font-weight: 400;
    font-size: 1rem;
    padding: .75rem 1.5rem;
    margin-bottom: 2px;
}

.sidebar .nav-link:hover {
    background-color: var(--hover-bg);
}

.sidebar .nav-link.active {
    color: var(--bs-primary);
    background-color: var(--active-color);
}

.sidebar .dropdown-menu {
    width: 100%;
    background-color: white;
}

.sidebar .dropdown-menu li {
    padding: 0 .5rem;
}

.sidebar .dropdown-menu li a.nav-link {
    border-radius: var(--bs-border-radius);
    color: var(--text-color);
}

.sidebar .dropdown-menu li a.nav-link:hover {
    background-color: var(--hover-bg);
}

.dropdown-divider {
    border-top: 1px solid #e9ecef;
}

.sidebar .dropdown-menu .dropdown-menu {
    padding: .5rem;
}

.sidebar .dropdown-menu .dropdown-item {
    border-radius: .25rem;
}

.sidebar .dropdown-menu .dropdown-item:focus,
.sidebar .dropdown-menu .dropdown-item:active,
.sidebar .dropdown-menu .dropdown-item:hover {
    color: var(--bs-body-color);
    background-color: var(--hover-bg);
}

.sidebar .dropdown-menu .dropdown-item.active {
    color: var(--bs-body-color);
    background-color: var(--active-color);
}

.sidebar h6 {
    padding: 1.5rem 1rem .5rem 1rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    color: #444;
}

/* Components */
.table {
    margin-bottom: 1.5rem;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: var(--main-background-color);
}

.section-title {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
}

.section-card {
    margin-bottom: 2rem;
}

.card-title {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.preview-box {
    border: 1px dashed #6c757d;
    border-radius: 0.5rem;
    padding: 1rem;
}

.data-list {}

.data-list-item {
    padding: .75rem 1rem;
}

.data-list-item:hover {
    background-color: var(--hover-bg);
    border-radius: var(--bs-border-radius);
}

.data-list-item.clickable {
    cursor: pointer;
}

.data-list-limits {
    font-size: 0.75rem;
    text-align: center;
    color: #444;
}

.suggested-partner-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.suggested-partner-list a {
    flex: 1 0 20%;
    border-radius: var(--bs-border-radius);
}

.suggested-partner-list a:hover {
    background-color: var(--hover-bg);
}

.suggested-partner-list a img {
    max-width: 100%;
}

.affiliate-disclaimer {
    font-size: 0.75rem;
    color: #888;
}

/* Footer */
.page-footer {
    font-size: .75rem;
    max-width: 50rem;
}

.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.footer-links>div {
    padding: .5rem .5rem;
}

/* Cookie consent */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: var(--bs-card-bg);
    text-align: center;
    z-index: 999;
    box-shadow: var(--card-shadow);
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .App {
        padding-top: 0;
    }

    .sidebar-logo img {
        height: 2rem;
    }

    .sidebar {
        padding-top: 3rem;
    }

    .sidebar-toggle-header {
        margin-bottom: 2rem;
    }

    .sidebar-toggle-header-mobile {
        display: none;
    }

    .sidebar-toggle-header .btn {
        display: none;
    }

    .main-container {
        height: 100vh;
    }

    .main-content {
        padding: 2.5rem 4rem 2.5rem 0;
    }

    .sidebar-container {
        position: relative;
        left: 0;
        width: 18rem;
        margin-right: 4rem;
    }

    .footer-links {
        flex-direction: row;
        line-height: 1;
    }

    .footer-links>div {
        padding: 0 .5rem;
        border-right: 1px solid #111;
    }

    .footer-links>div:last-child {
        border-right: none;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}