:root {
    /* Colors - Base */
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;

    /* Colors - Theme specific */
    --bs-body-bg: #f9f6f2;
    --bs-body-color: #111;
    --bs-link-color: var(--bs-primary);
    --bs-link-hover-color: #0a58ca;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;

    /* Spacing */
    --bs-spacer: 1rem;
    --bs-spacer-x: var(--bs-spacer);
    --bs-spacer-y: var(--bs-spacer);
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    /* Typography */
    --bs-font-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-heading-color: var(--bs-emphasis-color);

    /* Border & Shadows */
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-radius: 12px;
    --bs-border-radius-sm: 8px;
    --bs-border-radius-lg: 20px;
    --bs-border-radius-xl: 24px;
    --bs-border-radius-pill: 50rem;
    --bs-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, .075);
    --bs-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    --bs-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, .175);

    /* Components */
    --bs-component-active-bg: var(--bs-primary);
    --bs-component-active-color: #fff;

    /* Buttons */
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    --bs-btn-disabled-opacity: 0.65;

    /* Forms */
    --bs-form-valid-color: var(--bs-success);
    --bs-form-invalid-color: var(--bs-danger);
    --bs-form-input-bg: #fff;
    --bs-form-input-disabled-bg: var(--bs-secondary-bg);
    --bs-form-input-padding-x: 0.75rem;
    --bs-form-input-padding-y: 0.375rem;
    --bs-form-input-font-size: 1rem;
    --bs-form-input-line-height: 1.5;
    --bs-form-input-border-radius: var(--bs-border-radius);

    /* Badges */
    --bs-badge-padding-x: 0.75rem;
    --bs-badge-padding-y: 0.25rem;
    --bs-badge-font-size: 0.85rem;
    --bs-badge-font-weight: normal;
    --bs-badge-border-radius: var(--bs-border-radius-lg);
    --bs-badge-bg: var(--bs-gray-200);
    --bs-badge-color: #111;

    /* Custom Theme */
    --hover-bg: #f0eae2;
    --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    --card-shadow-hover: 0 4px 12px rgba(0, 0, 0, 0.1);
    --transition-base: all 0.2s ease-in-out;

    /* Additional Theme Colors */
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #f0f0f0;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #666666;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;

    /* Theme Semantic Colors */
    --bs-text-muted: var(--bs-gray-600);
    --bs-card-bg: #ffffff;
    --bs-badge-bg: var(--bs-gray-200);
    --bs-badge-color: var(--bs-gray-600);
    
    /* Social Links */
    --social-link-size: 2.5rem;
    --social-link-font-size: 1.5rem;
    --social-link-padding: 0.5rem;
    
    /* Project Cards */
    --project-card-gap: 1.25rem;
    --project-card-padding: 1.5rem;
    --project-logo-size: 48px;
    --project-logo-size-mobile: 40px;
    --project-title-size: 1.2rem;
    --project-description-size: 0.95rem;
}

/* Bootstrap component overrides */
.btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    --bs-btn-disabled-opacity: 0.65;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s, box-shadow 0.2s;
}

.btn:hover {
    transform: translateY(-1px);
    box-shadow: var(--card-shadow-hover);
}

.card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-bg: #fff;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.5rem;
    
    border: none;
    box-shadow: var(--card-shadow);
    transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
    box-shadow: var(--card-shadow-hover);
}

.badge {
    --bs-badge-padding-x: 0.75rem;
    --bs-badge-padding-y: 0.25rem;
    --bs-badge-font-size: 0.85rem;
    --bs-badge-font-weight: normal;
    --bs-badge-border-radius: var(--bs-border-radius-lg);
    
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    border-radius: var(--bs-badge-border-radius);
    font-weight: var(--bs-badge-font-weight);
    font-size: var(--bs-badge-font-size);
}

/* Social icons */
.social-link {
    color: var(--bs-body-color);
    font-size: var(--social-link-font-size);
    padding: var(--social-link-padding);
    border-radius: 50%;
    width: var(--social-link-size);
    height: var(--social-link-size);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s, background 0.2s;
}

.social-link:hover {
    transform: scale(1.1);
    background: var(--hover-bg);
    color: var(--bs-body-color);
}

.text-muted {
    color: var(--bs-text-muted) !important;
}

/* Project cards enhanced */
.card.project-card {
    display: flex;
    gap: var(--project-card-gap);
    align-items: flex-start;
    background: var(--bs-card-bg);
    border-radius: var(--bs-border-radius);
    padding: var(--project-card-padding);
    text-decoration: none;
    color: var(--bs-body-color);
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: var(--card-shadow);
    text-align: left;
}

.card.project-card:hover {
    transform: translateY(-2px);
    box-shadow: var(--card-shadow-hover);
    color: var(--bs-body-color);
}

/* Add responsive styles */
@media (max-width: 768px) {
    .card.project-card {
        padding: 1rem;
        gap: 1rem;
    }
}

.accordion {
    --bs-accordion-bg: var(--bs-card-bg);
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: var(--bs-border-radius);
    --bs-accordion-btn-padding-y: 1.5rem;
    --bs-accordion-btn-padding-x: 1.5rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-card-bg);
    --bs-accordion-btn-icon-color: var(--bs-body-color);
    --bs-accordion-btn-focus-border-color: transparent;
    --bs-accordion-btn-focus-box-shadow: var(--card-shadow);
    --bs-accordion-body-padding-y: 0;
    --bs-accordion-body-padding-x: 1.5rem;
    --bs-accordion-body-padding-bottom: 1.5rem;
    --bs-accordion-active-bg: var(--bs-card-bg);
    --bs-accordion-active-color: var(--bs-body-color);
    
    gap: 1rem;
}

.accordion-item {
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-accordion-inner-border-radius) !important;
    box-shadow: var(--card-shadow);
    transition: box-shadow 0.2s;
    margin-bottom: 1rem;
}

.accordion-item:hover {
    box-shadow: var(--card-shadow-hover);
}

.accordion-button {
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-weight: 600;
    color: var(--bs-accordion-btn-color);
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: var(--bs-accordion-inner-border-radius) !important;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.accordion-button:focus {
    border-color: var(--bs-accordion-btn-focus-border-color);
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-x);
    padding-bottom: var(--bs-accordion-body-padding-bottom);
    background-color: var(--bs-accordion-bg);
}

.accordion-button::after {
    color: var(--bs-accordion-btn-icon-color);
}

/* Add to theme.css */
.modal {
    --bs-modal-padding: var(--bs-spacer-y);
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-header-padding: var(--bs-spacer-y) var(--bs-spacer-x);
    --bs-modal-bg: var(--bs-card-bg);
    --bs-modal-border-width: 0;
    --bs-modal-box-shadow: var(--card-shadow);
}

.modal-content {
    background: var(--bs-modal-bg);
    border-width: var(--bs-modal-border-width);
    border-radius: var(--bs-modal-border-radius);
    box-shadow: var(--bs-modal-box-shadow);
}

.modal-header {
    padding-bottom: 0;
    border-bottom: 0;
}

.modal-footer {
    padding-top: 0;
    border-top: 0;
}